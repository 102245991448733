
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import MoreInfo from "@/app/components/modals/MoreInfo.vue";

export default Vue.extend({
    components: { MoreInfo },
    props: {
        isLoadingOutside: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isLoading: false,
            currentPage: 1,
            perPage: 10,
            rows: 100,
            search: "",
            rolesArray: ["ReportCreator", "ReportReviewer", "ReportSignatory", "Admin"],
            fields: [
                {
                    key: "details",
                    label: "Details",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "position",
                    label: "Position",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },
                {
                    key: "roles",
                    label: "Role(s)",
                    tdClass: "align-middle text-center",
                    thClass: "align-middle text-center",
                },
                {
                    key: "entry_status",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
                {
                    key: "action",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
            ],
            items: [
                {
                    fullName: "Benjamin Samson",
                    email: "benjamin.sam@bodadmin.com",
                    imageUrl: "",
                    role: "Creator",
                    entry_status: null,
                },
                // {
                //     name: "Vivien Grace",
                //     email: "vivien.grace@bodadmin.com",
                //     imageUrl: "",
                //     roles: [
                //         {
                //             name: "Director",
                //         },
                //         {
                //             name: "Executive",
                //         },
                //     ],
                //     committees: 3,
                //     entry_status: "Draft",
                // },
            ],
        };
    },
    computed: {
        ...mapGetters({
            activeUsers: "users/activeUsers",
        }),
        ...mapState("users", ["users"]),
        ...mapState("users", {
            creators: (state: any) => state.levels?.find((level: any) => level.category === "ReportCreator")?.users,
        }),
        ...mapState("users", {
            reviewers: (state: any) => state.levels?.find((level: any) => level.category === "ReportReviewer")?.users,
        }),
        ...mapState("users", {
            signatories: (state: any) => state.levels?.find((level: any) => level.category === "ReportSignatory")?.users,
        }),
        hasAReviewer(): boolean {
            return this.reviewers?.length > 0;
        },
        hasASignatory(): boolean {
            return this.signatories?.length > 1;
        },
        isRoleMissing(): boolean {
            return !this.hasAReviewer && !this.hasASignatory;
        },
        defaultActions() {
            return [
                {
                    text: "User details",
                    action: (item: any) => {
                        this.$emit("toggleUserView", item);
                    },
                },
                {
                    text: "Edit User & User Role(s)",
                    action: (item: any) => {
                        this.$emit("toggleUpdate", item);
                    },
                },
                {
                    text: "Generate new credentials",
                    action: (item: any) => {
                        this.$emit("toggleCredentials", item);
                    },
                },
                {
                    text: "Disable User",
                    action: (item: any) => {
                        this.disableUser(item.id);
                    },
                },
            ];
        },
        makeActions(): any[] {
            return [];
        },
        activeUsers(): any {
            return this.users;
        },
    },
    methods: {
        ...mapActions({
            fetchLevels: "users/fetchLevels",
            fetchUsers: "users/fetchUsers",
            fetchActiveUsers: "users/fetchActiveUsers",
            fetchPositions: "company/fetchPositions",
            makeCreator: "reports/makeCreator",
            makeReviewer: "reports/makeReviewer",
            makeSignatory: "reports/makeSignatory",
            updateUserRoles: "users/updateSingleUserRoles",
            deleteSingleUser: "users/deleteSingleUser",
            updateUserStatus: "users/updateUserStatus",
        }),

        toggleInfoModal() {
            this.$bvModal.show("modal-more-info");
        },
        getActions(roleIds: Array<string>): any {
            if (roleIds) {
                const userMakeActions = this.makeActions.filter((makeAction) => !roleIds.includes(makeAction.id));
                return [...this.defaultActions, ...userMakeActions];
            }
            return [...this.defaultActions, ...this.makeActions];
        },
        async disableUser(userId: string) {
            const status = 4;
            this.isLoading = true;
            try {
                await this.updateUserStatus({ userId, status });

                this.$bvToast.toast("User disabled successfully", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                });
                await this.fetchUserList();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to remove User!`,
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async updateRole({ item, roles, userType }: { item: any; roles: Array<string>; userType: string }) {
            const { id } = item;

            const body = [...roles];
            this.isLoading = true;
            try {
                await this.updateUserRoles({
                    userId: id,
                    body,
                });
                this.$bvToast.toast("Success", {
                    title: `Made ${userType} successfully`,
                    variant: "success",
                    solid: true,
                });
                await this.fetchUserList();
                await this.fetchLevels();
            } catch (error) {
                this.$bvToast.toast("Error", {
                    title: `Unable to make ${userType}!`,
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.isLoading = false;
            }
        },
        async fetchCompanyPositions() {
            this.isLoading = true;
            try {
                await this.fetchPositions();
            } catch (error) {
                // return error;
            }
            this.isLoading = false;
        },
        async fetchUserList() {
            this.isLoading = true;
            try {
                await this.fetchUsers();
                await this.fetchActiveUsers();
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
    },
    async mounted() {
        await this.fetchUserList();
        await this.fetchCompanyPositions();
    },
});
