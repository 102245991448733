var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid py-6 mt-body bg-gray-10 min-vh-100"
  }, [_vm.isCompanyCompleted ? _c('div', {
    staticClass: "checklist"
  }, [_c('ba-base-icon', {
    staticClass: "checklist__cancel-icon",
    attrs: {
      "name": "ba-cancel-modal"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.closeModal.apply(null, arguments);
      }
    }
  }), _c('div', [_vm._m(0), _c('b-form-checkbox', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "id": "checkbox-onboarding-2",
      "disabled": "",
      "checked": _vm.isCompanyProfileComplete,
      "name": "checkbox-onboarding-2"
    }
  }, [_c('div', {
    staticClass: "checklist-checked__text"
  }, [_vm._v("Complete Company Profile")])]), _c('h1', {
    staticClass: "checklist__title pt-4"
  }, [_vm._v("NEXT")]), _c('b-form-checkbox', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "id": "checkbox-onboarding-2",
      "disabled": "",
      "checked": false,
      "name": "checkbox-onboarding-2"
    }
  }, [_c('div', {
    staticClass: "checklist-checked__text"
  }, [_vm._v("Add 2 signatories (Board Chairman & Company Secretary)")])]), _c('b-form-checkbox', {
    staticClass: "d-flex align-items-center mt-3",
    attrs: {
      "id": "checkbox-onboarding-2",
      "disabled": "",
      "checked": false,
      "name": "checkbox-onboarding-2"
    }
  }, [_c('div', {
    staticClass: "checklist-checked__text"
  }, [_vm._v("Add reviewer")])]), _c('ba-button', {
    staticClass: "text-gray-dark border-0 bg-yellow-70 mt-4",
    attrs: {
      "text": "Continue"
    },
    on: {
      "click": _vm.goToNextStep
    }
  })], 1)], 1) : _vm._e(), _c('pageHeader', {
    attrs: {
      "title": "Company profile",
      "description": "Edit company profile and settings"
    }
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-loader-wrapper', {
    attrs: {
      "isLoading": _vm.isLoading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "7"
    }
  }, [_c('section', {
    staticClass: "profile-info-section pl-5 lg-pr-0 pr-5 pt-lg-0 pt-0 lg-pt-4"
  }, [_c('company-details', {
    on: {
      "toggleCompanyEdit": function ($event) {
        return _vm.$refs.compLogo.toggleEditingInfo($event);
      }
    }
  })], 1)]), _c('b-col', {
    staticClass: "pr-5 pl-lg-0 pl-5 pb-lg-0 pb-5",
    attrs: {
      "cols": "12",
      "lg": "5"
    }
  }, [_c('company-logos', {
    ref: "compLogo"
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h1', {
    staticClass: "checklist__title"
  }, [_vm._v("CHECKLIST")]), _c('div', {
    staticClass: "checklist-counter"
  }, [_c('p', {
    staticClass: "checklist-counter__count"
  }, [_vm._v("1/3")])])]);

}]

export { render, staticRenderFns }