
import Vue from "vue";
import { formatDate } from "@/utils/helpers";

export default Vue.extend({
    name: "PreviewUserModal",
    props: {
        user: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    methods: {
        formatDate,
    },
});
