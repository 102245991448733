var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-tab h-100"
  }, [!_vm.isLoading && _vm.items.length == 0 ? _c('ba-base-tab', {
    attrs: {
      "icon": "ba-empty-human-capital",
      "emptyText": "You haven't created any users' profile yet. To begin, create a <br/> user. "
    }
  }) : _c('ba-table', {
    attrs: {
      "currentPage": _vm.currentPage,
      "perPage": _vm.perPage,
      "rows": _vm.rows,
      "fields": _vm.fields,
      "items": _vm.disabledUsers,
      "isLoading": _vm.isLoading || _vm.isLoadingOutside
    },
    scopedSlots: _vm._u([{
      key: "header-action-right",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "text-gray-dark border bg-transparent rounded-custom border-gray-40 px-4 py-2 mr-4",
          attrs: {
            "id": "BA_AMP_MORE_INFO"
          },
          on: {
            "click": _vm.toggleInfoModal
          }
        }, [_c('ba-base-icon', {
          attrs: {
            "name": "ba-info-icon"
          }
        }), _vm._v("More info")], 1), _c('b-button', {
          staticClass: "text-gray-dark bg-yellow-70 rounded-custom border-0 px-4 py-2",
          attrs: {
            "id": "BA_AMP_CREATE_USER"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('createUser');
            }
          }
        }, [_vm._v("Create User")])], 1)];
      },
      proxy: true
    }, {
      key: "tooltip-section",
      fn: function () {
        return [_c('p', {
          staticClass: "mb-2 mt-4 total--count"
        }, [_vm._v("Total: " + _vm._s(_vm.disabledUsers.length))])];
      },
      proxy: true
    }, {
      key: "cell(details)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "default-profile-image mr-1"
        }, [_c('p', {
          staticClass: "mb-0 text-white text-uppercase"
        }, [_vm._v(_vm._s(data.item.fullName[0].toUpperCase()) + _vm._s(data.item.fullName[1].toUpperCase()))])]), _c('div', {
          staticClass: "ml-2"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('h3', {
          staticClass: "user--name mb-0"
        }, [_vm._v(_vm._s(data.item.fullName))])])])])];
      }
    }, {
      key: "cell(committees)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('ba-base-icon', {
          attrs: {
            "name": "ba-account-group",
            "height": "20px"
          }
        }), _c('div', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(data.item.committees))])], 1)];
      }
    }, {
      key: "cell(roles)",
      fn: function (data) {
        return [data.item.roleIds.length === 0 ? _c('ba-badge', {
          attrs: {
            "text": "Profile-Only"
          }
        }) : _c('div', {
          staticClass: "roles d-flex justify-content-center"
        }, _vm._l(data.item.roleIds, function (role, ix) {
          return _c('ba-badge', {
            key: ix,
            attrs: {
              "text": role
            }
          });
        }), 1)];
      }
    }, {
      key: "cell(position)",
      fn: function (data) {
        return [_c('p', {
          staticClass: "position-text mb-0"
        }, [_vm._v(_vm._s(data.item.position))])];
      }
    }, {
      key: "cell(action)",
      fn: function (data) {
        return [_c('ba-base-icon', {
          attrs: {
            "name": "ba-lock-icon"
          }
        }), _c('b-dropdown', {
          attrs: {
            "menu-class": "my-0 py-0",
            "variant": "transparent",
            "boundary": "viewport",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, _vm._l(_vm.getActions(data.item.roleIds), function (itemOption, ix) {
          return _c('b-dropdown-item', {
            key: ix,
            on: {
              "click": function ($event) {
                return itemOption.action(data.item);
              }
            }
          }, [_c('div', {
            class: `${itemOption.text === 'Disable User' ? 'text-red-70' : 'text-gray-dark'} option--text py-2`
          }, [_vm._v(_vm._s(itemOption.text))])]);
        }), 1)];
      }
    }])
  }), _c('MoreInfo')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }