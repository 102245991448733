var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "corporate-universe container-fluid py-6 mt-body"
  }, [_c('div', {
    staticClass: "d-flex mb-2 pointer text-black",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: _vm.HOME.INDEX.NAME
        });
      }
    }
  }, [_c('ba-base-icon', {
    staticClass: "mr-2 pointer",
    attrs: {
      "height": "17px",
      "name": "ba-home"
    }
  }), _c('span', {
    staticClass: "text-underline"
  }, [_vm._v("Go Home")])], 1), _c('cu-home-banner'), _c('b-row', {
    staticClass: "mb-10"
  }, _vm._l(_vm.settingsDivisions, function (corporateUniverseDivision, ix) {
    return _c('b-col', {
      key: ix,
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('cu-menu', {
      attrs: {
        "title": corporateUniverseDivision.title,
        "description": corporateUniverseDivision.description,
        "logo": corporateUniverseDivision.logo,
        "route": corporateUniverseDivision.route
      }
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }