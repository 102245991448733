<template>
    <div>
        <h1 class="faq__text--header">How Tos</h1>
        <input
            v-model="search"
            type="text"
            placeholder="Search..."
            class="form-control input__field mt-3 mb-2"
        />
        <section v-for="(data, index) in filteredQAs" :key="index">
            <h4 v-if="data.type == 'headTitle'"  class="pt-3 setup-title">{{ data.headTitle }}</h4>
            <div v-if="data.type === 'QA'" class="question-section bg-gray-dark px-3 py-3 mb-3">
                <div class="d-flex justify-content-between align-items-center mb-0 pointer"  @click="collapsedPart(index)">
                    <p class=" text-black-10 mb-0">{{ data.question }}</p>
                    <div class="pointer mt-2">
                        <ba-base-icon name="ba-arrow-down" />
                    </div>
                </div>
                <div :class="collapsedPartIds.find((i) => i === index) !== undefined ? 'p-3 mt-3': 'hide_form p-0'" class="answer-section text-white">
                    <p v-for="(answer, ix) in data.answer" :key="ix">{{ answer }}</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'HowTos',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data:() => ({
        search: '',
        collapsedPartIds: [],
        howTos: [
            { headTitle: 'reporting', type: 'headTitle'},
            {
                question: 'How to create a report', 
                answer: [
                    '1. How to create a report',
                    '2. Complete the checklist by following the steps required by',
                    '3. Click on the “Go to dashboard” button if you are not automatically redirected to the reporting dashboard.',
                    '4. Click on the “Create Report” button to start creating a report.',
                    '5. Click on “Create New…” to start creating a brand new report, or “Create from existing…” to create from an already existing report.',
                    '6. Choose the timeline you would like to report for, typically one calendar year of reference.',
                    '7. Go through the instructions for NCCG, and click the “Confirm” button.',
                    '8. Fill in information required for various sections of the report, like Report details, Governance Stakeholders and Governance Structures.',
                    '9. Start responding to the principles one after another by answering the questions and providing explanations and documentation where applicable.',
                    '10. Search to add the signatories who are to sign the report from the list of signatories which you have already created.',
                    '11. Click “Preview report” to see all of the information you have entered so far. If all looks good, close the preview to continue.',
                    '12. Click the “Submit Report” button to then submit the report for internal review and approval.'
                ], 
                type: 'QA'
            },
            { 
                question: 'How to approve a report as a reviewer',
                answer: [
                    '1. Make sure you have been assigned the role of “Report Reviewer” by the admin and make sure the report was assigned to you for review by the creator.',
                    '2. Go to the reporting dashboard, and navigate to the “My desk - Review” tab.',
                    '3. Click on the report awaiting your approval to review the details.',
                    '4. After review, click the “Approve(Review)” button.'
                ], 
                type: 'QA'
            },
            { 
                question: 'How to sign a report as a signatory',
                answer: [
                    '1. Make sure you have been assigned the role of “Report Signatory” by the admin.',
                    '2. Go to the reporting dashboard, and navigate to the “My desk - Signatory” tab.',
                    '3. Click on the report awaiting your signature to review the details',
                    '4. After review, click “Sign Report” to open up a modal where you upload your signature.',
                    'Please note that both signatories of the report has to sign for the report to be considered “signed”.'
                ], 
                type: 'QA'
            },
            { 
                question: 'How to submit a report to the FRC',
                answer: [
                    '1. Go to the reporting dashboard and click on the fully signed report to see the details',
                    '2. Click on the “Submit to FRC” button in the top right, and follow through the payment link to complete the submission.',
                    '3. Click the “Save password” button to save your new password.'
                ], 
                type: 'QA'
            },
            { headTitle: 'user management', type: 'headTitle'},
            {
                question: 'How to create a user', 
                answer: [
                    '1. Click your profile picture in the top right, to open up the avatar menu. Then select the “Users, roles & profile” option.',
                    '2. Click the “Create user” button to open up the create user modal.',
                    '3. Fill in all the personal details of the user including first name, last name, email address, phone number.',
                    '4. Select the position of the user in your organisation',
                    '5. Select a role that is appropriate for the user from the options including, Report creator, Report reviewer, Report signatory, or Admin.',
                    '6. To learn more about the different roles, click the “More info” button beside the create user button.'
                ], 
                type: 'QA'
            },
            {
                question: 'How to create a signatory', 
                answer: [
                    '1. Click your profile picture in the top right, to open up the avatar menu. Then select the “Users, roles & profile” option.',
                    '2. Click the “Create User” button, to open up the create user modal.',
                    '3. Fill in all the required information like the user’s name, email, phone number and position.',
                    '4. Select “Report Signatory” from the dropdown as the role for this user.',
                    '5. Click the “Save” button to save this user.'
                ], 
                type: 'QA'
            },
            {
                question: 'How to edit, change and update user roles and permissions', 
                answer: [
                    '1. Click your profile picture in the top right, to open up the avatar menu. Then select the “Users, roles & profile” option.',
                    '2. Find the user you would like to edit/update and click the menu button “ ⁝ ” on the right end of the user item to open up the context menu.',
                    '3. Click on “Edit User and User Role(s)” to open up the user details modal.',
                    '4. Edit and update the details the user’s details, including their first name, last name, phone number, date of appointment, position and role.',
                    '5. Click the “Save” button to save your changes.'
                ], 
                type: 'QA'
            },
            {
                question: 'How to send credentials to a user', 
                answer: [
                    '1. Click your profile picture in the top right, to open up the avatar menu. Then select the “Users, roles & profile” option.',
                    '2. Click the context menu button “︙” to the far right of the user’s name.',
                    '3. Select the “Send credentials”'
                ], 
                type: 'QA'
            },
            {
                question: 'How to change your password', 
                answer: [
                    '1. Click your profile picture in the top right, to open up the avatar menu. Then select the “Change password” option.',
                    '2. Enter your current password, and then create a new password and then enter it in the required fields.',
                    '3. Click the “Save password” button to save your new password.'
                ], 
                type: 'QA'
            },
        ]
    }),
    computed: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        filteredQAs () {
            if (!this.search) return this.howTos;
            return this.howTos?.filter((howTo) => howTo.question?.toLowerCase()?.includes(this.search.toLowerCase()));
        }
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        collapsedPart (id) {
            if (this.collapsedPartIds.find((i) => i === id) !== undefined) {
              this.collapsedPartIds = this.collapsedPartIds.filter((i) => i !== id)
            } else this.collapsedPartIds = [...this.collapsedPartIds, id]
      },
    }
};
</script>

<style lang="scss" scoped>
.answer {
    // color: #3c393b !important;
    font-weight: 500 !important;
    font-style: normal !important;
}
.turn {
    transform: rotate(180deg) !important;
    transition: all .2s ease-in-out;
}
.setup-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #A7A6A6;
    padding-bottom: 8px;
}
.question-section {
    border-radius: 8px;
}
.answer-section {
    border-radius: 8px;
    background-color: #232123;
}
.input__field {
    border: 1px solid #383637 !important;
    border-radius: 8px !important;
    height: 45px;
    background: transparent !important;
}
input {
    color: #E9E9E9 !important;
}
.faq {
    &__wrapper {
        background: #232123;
        border: 1px solid #383637;
        border-radius: 8px;
        padding: 24px;
        height: 786px;
        overflow: scroll;
    }
    &__text {
        &--header {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #FFFFFF; 
        }
    }
}
.hide_form {
    height: 0px;
    overflow: hidden;
}
</style>
