var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "profile-info py-4 px-4 mb-5"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "12"
    }
  }, [_c('b-row', _vm._l(_vm.formFields, function (field) {
    return _c('b-col', {
      key: field.name,
      attrs: {
        "cols": _vm.editDetails ? 6 : 6
      }
    }, [!_vm.editDetails ? _c('div', {
      staticClass: "mb-4"
    }, [_c('p', {
      staticClass: "label--text mb-1"
    }, [_vm._v(_vm._s(field.label))]), _c('div', {
      staticClass: "details--text"
    }, [_vm._v(" " + _vm._s(_vm.profile[field.key] ? field.type === "date" ? _vm.formatDateToLocale(_vm.profile[field.key]) : _vm.profile[field.key] : "Not Provided") + " ")])]) : _c('div', {
      staticClass: "mb-4"
    }, [field.type === 'textarea' ? _c('ba-textarea', {
      staticClass: "text-black-90",
      attrs: {
        "disabled": field.disabled,
        "label": field.label,
        "classText": "white",
        "required": field.isRequired
      },
      on: {
        "blur": function ($event) {
          _vm.$v.profile[field.key].$touch();
        }
      },
      model: {
        value: _vm.profile[field.key],
        callback: function ($$v) {
          _vm.$set(_vm.profile, field.key, $$v);
        },
        expression: "profile[field.key]"
      }
    }) : field.type === 'date' ? _c('ba-datepicker', {
      staticClass: "text-black-90",
      attrs: {
        "disabled": field.disabled,
        "label": field.label,
        "value": _vm.profile[field.key],
        "classText": "white",
        "required": field.isRequired
      },
      on: {
        "blur": function ($event) {
          _vm.$v.profile[field.key].$touch();
        }
      },
      model: {
        value: _vm.profile[field.key],
        callback: function ($$v) {
          _vm.$set(_vm.profile, field.key, $$v);
        },
        expression: "profile[field.key]"
      }
    }) : field.type === 'dropdown' ? _c('div', [_c('ba-dropdown', {
      attrs: {
        "disabled": field.disabled,
        "variant": "white",
        "label": field.label,
        "required": field.isRequired,
        "options": _vm.dropDowns[field.key]
      },
      on: {
        "blur": function ($event) {
          _vm.$v.profile[field.key].$touch();
        }
      },
      model: {
        value: _vm.profile[field.key],
        callback: function ($$v) {
          _vm.$set(_vm.profile, field.key, $$v);
        },
        expression: "profile[field.key]"
      }
    })], 1) : _c('ba-text-field', {
      staticClass: "text-black-90",
      attrs: {
        "disabled": field.disabled,
        "label": field.label,
        "type": field.type,
        "classText": "white",
        "required": field.isRequired
      },
      on: {
        "blur": function ($event) {
          _vm.$v.profile[field.key].$touch();
        }
      },
      model: {
        value: _vm.profile[field.key],
        callback: function ($$v) {
          _vm.$set(_vm.profile, field.key, $$v);
        },
        expression: "profile[field.key]"
      }
    })], 1)]);
  }), 1)], 1), _vm.editDetails ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-button', {
    staticClass: "mb-4 text-black",
    attrs: {
      "loading": _vm.loading,
      "text": "Save Changes",
      "variant": "warning"
    },
    on: {
      "click": _vm.saveDetails
    }
  })], 1) : _vm._e(), !_vm.editDetails ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "edit__btn"
  }, [_c('ba-button', {
    staticClass: "text-white bg-gray-dark text-sm-3",
    attrs: {
      "icon": "ba-edit-new",
      "text": "Edit information"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.toggleEditDetails.apply(null, arguments);
      }
    }
  }), _c('p', {
    staticClass: "edit__text"
  }, [_vm._v("Last edited " + _vm._s(_vm.formatDate(_vm.profile.dateModified)))])], 1)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }