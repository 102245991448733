var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cu-menu pt-4 pb-4 px-3 rounded pointer mb-2",
    class: {
      'bg-black': _vm.isHovered,
      'bg-white border border-gray-500': !_vm.isHovered
    },
    on: {
      "click": _vm.goToPage,
      "mouseenter": function ($event) {
        _vm.isHovered = true;
      },
      "mouseleave": function ($event) {
        _vm.isHovered = false;
      }
    }
  }, [_c('ba-base-icon', {
    staticClass: "mt-2 title-icon",
    class: {
      'text-white': _vm.isHovered,
      'text-black': !_vm.isHovered
    },
    attrs: {
      "name": _vm.logo
    }
  }), _c('div', {
    staticClass: "font-weight-normal cu-menu__title mt-2",
    class: {
      'text-white': _vm.isHovered,
      'text-black': !_vm.isHovered
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('div', {
    staticClass: "w-75 cu-menu__description",
    class: {
      'text-gray-700': _vm.isHovered,
      'text-black-50': !_vm.isHovered
    }
  }, [_vm._v(" " + _vm._s(_vm.description) + " ")]), _c('div', {
    staticClass: "cu-menu__bg"
  }, [_c('ba-base-icon', {
    staticClass: "mt-2 icon",
    class: {
      'text-warning': _vm.isHovered,
      'text-gray-300': !_vm.isHovered
    },
    attrs: {
      "name": _vm.logo
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }