<template>
    <section>
        <div>
            <h1 class="faq__text--header">Frequently asked questions</h1>
            <input
                v-model="search"
                type="text"
                placeholder="Search..."
                class="form-control input__field mt-3 mb-2"
            />
            <section v-for="(data, index) in filteredQAs" :key="index">
                <h4 v-if="data.type == 'headTitle'"  class="pt-3 setup-title">{{ data.headTitle }}</h4>
                <div v-if="data.type === 'QA'" class="question-section bg-gray-dark px-3 py-3 mb-3">
                    <div class="d-flex justify-content-between align-items-center mb-0 pointer"  @click="collapsedPart(index)">
                        <p class=" text-black-10 mb-0">{{ data.question }}</p>
                        <div class="pointer mt-2">
                            <ba-base-icon name="ba-arrow-down" />
                        </div>
                    </div>
                    <div :class="collapsedPartIds.find((i) => i === index) !== undefined ? 'p-3 mt-3': 'hide_form p-0'" class="answer-section text-white">
                        <div v-if="data.subType === 'multiple'">
                            <p v-for="(answer, ix) in data.answer" :key="ix">{{ answer }}</p>
                        </div>
                        <p  v-if="data.subType === 'single'">{{ data.answer }}</p>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
export default {
    name: 'allFaqs',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data:() => ({
        search: '',
        collapsedPartIds: [],
        allFaqs: [
            { headTitle: 'ACCOUNT SETUP', type: 'headTitle'},
            {
                question: 'Can I change my user ID', 
                answer: 'Every user has a Unique ID upon sign-up that cannot be changed. However, where you are unable to retrieve your User ID, kindly send an email to service@bodadmin.com stating your concern and you will be duly attended to.', 
                type: 'QA',
                subType: 'single'
            },
            { 
                question: 'I have signed up on the system and I am yet to get a verification email? How do I go about this?',
                answer: [
                    'There are several factors that could prevent you from receiving a verification email. One of which can be your email provider and as such we advise you to check your email server to know if there’s any block policy as this can prevent you from receiving a verification email.',
                    'Also, check your spam/junk mails as sometimes emails drop there. Where the status remains the same, kindly try a different email and click the “resend verification link” at the top left corner of the sign-up page. If the above steps are not successful, please send us an email at service@bodadmin.com'
                ],
                type: 'QA',
                subType: 'multiple'
            },
            { headTitle: 'USERS AND ROLE ASSIGNMENT', type: 'headTitle'},
            { 
                question: 'Can a compliance officer be a reviewer and creator at the same time?',
                answer: 'Yes, a compliance officer can be a reviewer at the same time. This also extends to other users in the system. Also, be informed that when creating a report, ensure you assign two (2) roles to 2 different users for effective documentation.', 
                type: 'QA',
                subType: 'single'
            },
            { 
                question: 'Who can be a Signatory?',
                answer: [
                    'Only 2 persons can be signatories to a report. They are 1. Board Chairman or Chairman, committee responsible for governance and 2. Company secretary or compliance officer.',
                    'Please note that 2 persons of a particular group cannot be signatories to a report. For example: It is inappropriate to put the Board Chairman and Chairman of the committee responsible for Governance (WRONG) as signatories to a report.',
                    'A proper structure is making a Chairman, committee responsible for Governance and the Company Secretary (CORRECT) signatories to a report.'
                ], 
                type: 'QA',
                subType: 'multiple'
            },
            {
                question: `Can I edit the company's details?`, 
                answer: [
                    'Yes, you can. Log on to your dashboard, go to settings, click on company settings and proceed to make the necessary alteration.',
                    `Also, you cannot alter the company's name and registration number once it's been saved in the system. If that is the case, kindly send an email to service@bodamin.com for assistance.`
                ], 
                type: 'QA',
                subType: 'multiple'
            },
            {
                question: 'How do I go about the reporting process?', 
                answer: `The reporting process is seamless and every step is comprehensive. There's also a demo video on the portal to help you through the process. To watch the video kindly follow this link https://nccg2018financialreportingcouncil.bodadmin.com/login`, 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Can 1 person be assigned multiple roles?', 
                answer: 'Yes, one person can be assigned multiple roles in the system. However when creating a report, we advise that you do not assign multiple roles to one (1) person.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Can you delete an existing user from the system?', 
                answer: `No, you cannot outrightly delete an existing user from the system for record purposes. The available option is removing the user from performing a particular role in the system which automatically places a restriction on the user's function.`, 
                type: 'QA',
                subType: 'single'
            },
            { headTitle: 'GENERAL REPORT QUESTIONS', type: 'headTitle'},
            {
                question: 'While answering the 28 principles, is it compulsory to upload documents on each principle?', 
                answer: 'No, you do not have to upload documents on each principle. Where the principle requires you to upload a document, such a requirement will be expressly stated in the principle.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'What happens when I make multiple reports with the same timeline?', 
                answer: 'Where you create multiple reports within the same timeline or with an overlapping report period range, the later report will not be accepted by the system: this is because a report must be unique to a specific period.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Is the date of appointment the date the person was assigned a particular role or the date he was employed by the company?', 
                answer: 'The date of appointment is the date a person was assigned a particular role.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Can you review or delete a report once it has been submitted to the FRC?', 
                answer: 'No, you cannot revise, review or delete a report once it has been submitted to the commission.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Must all companies registered in the system have a website? What happens where otherwise is the case?', 
                answer: 'No, having a website is not a prerequisite to use the system.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'When a report is commented on by the FRC, is the comment available to all the users in the system or just the creator?', 
                answer: 'Yes, the comment made by the FRC is available to all the users in the system.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Is it compulsory for a company to have a Governance Evaluation consultant?', 
                answer: 'No, it is not compulsory to have a Governance evaluation consultant.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Can you create 1 report for more than one company?', 
                answer: 'No, you cannot create 1 report for multiple companies. Each report is coupled with a distinct corporate entity.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'For a Holding Company, can each subsidiary access the NCCG 2018 compliance reporting portal and submit their returns using a single portal?', 
                answer: 'No, each subsidiary is required to create a separate portal to file its own report.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'For companies who have reported for 2021, do they have to submit a new application using the portal?', 
                answer: 'No, you do not have to file a new report using the portal. However, we recommend that you do so for record purposes.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Can the duly completed softcopy of the report be accessed upon completion to enable us to send copies of the NCCG report to other regulators?', 
                answer: 'Yes, you can access a softcopy of the created report by downloading a PDF version from your portal. Furthermore, we advise that you download a copy before you get feedback from the council. This is because any commentary or remark made by the council will be accessible on the report.', 
                type: 'QA',
                subType: 'single'
            },
            {
                question: 'Is there a maximum number of rejections in a report/review cycle?', 
                answer: 'No, there is no limit on a report cycle.', 
                type: 'QA',
                subType: 'single'
            },
            { headTitle: 'COST AND FEES', type: 'headTitle'},
            {
                question: 'How much would it cost me to file a report to the FRC and also is the N10,000 Submission fee annually or on every report created?', 
                answer: 'You are only required to pay one fee which is the N10,000 fee paid for every report submitted. You can only submit one report in a year to the FRC.', 
                type: 'QA',
                subType: 'single'
            },
        ]
    }),
    computed: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        filteredQAs () {
            if (!this.search) return this.allFaqs;
            return this.allFaqs?.filter((howTo) => howTo.question?.toLowerCase()?.includes(this.search.toLowerCase()));
        }
    },
    methods: {
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        collapsedPart (id) {
            if (this.collapsedPartIds.find((i) => i === id) !== undefined) {
              this.collapsedPartIds = this.collapsedPartIds.filter((i) => i !== id)
            } else this.collapsedPartIds = [...this.collapsedPartIds, id]
      },
    }
};
</script>

<style lang="scss" scoped>

.answer {
    // color: #3c393b !important;
    font-weight: 500 !important;
    font-style: normal !important;
}
.turn {
    transform: rotate(180deg) !important;
    transition: all .2s ease-in-out;
}
.setup-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #A7A6A6;
    padding-bottom: 8px;
}
.question-section {
    border-radius: 8px;
}
.answer-section {
    border-radius: 8px;
    background-color: #232123;
}
.input__field {
    border: 1px solid #383637 !important;
    border-radius: 8px !important;
    height: 45px;
    background: transparent !important;
}
input {
    color: #E9E9E9 !important;
}
.faq {
    &__wrapper {
        background: #232123;
        border: 1px solid #383637;
        border-radius: 8px;
        padding: 24px;
        height: 786px;
        overflow: scroll;
    }
    &__text {
        &--header {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            color: #FFFFFF; 
        }
    }
}
.hide_form {
    height: 0px;
    overflow: hidden;
}
</style>
