var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-tab h-100"
  }, [!_vm.isLoading && _vm.items.length == 0 ? _c('ba-base-tab', {
    attrs: {
      "icon": "ba-empty-human-capital",
      "emptyText": "You haven't created any users' profile yet. To begin, create a <br/> user. "
    }
  }) : _c('ba-table', {
    attrs: {
      "currentPage": _vm.currentPage,
      "perPage": _vm.perPage,
      "rows": _vm.rows,
      "fields": _vm.fields,
      "items": _vm.reportReviewer.users || [],
      "isLoading": _vm.isLoading || _vm.isLoadingOutside
    },
    scopedSlots: _vm._u([{
      key: "search-section",
      fn: function () {
        return [_c('p', {
          staticClass: "mb-2 mt-4 total--count"
        }, [_vm._v("Reviewers: " + _vm._s(_vm.reportReviewer.users && _vm.reportReviewer.users.length || 0))])];
      },
      proxy: true
    }, {
      key: "cell(details)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "default-profile-image-user mr-1"
        }, [_c('p', {
          staticClass: "mb-0 text-white text-uppercase"
        }, [_vm._v(_vm._s(data.item.fullName[0].toUpperCase()) + _vm._s(data.item.fullName[1].toUpperCase()))])]), _c('h3', {
          staticClass: "user--name mb-0 ml-2"
        }, [_vm._v(_vm._s(data.item.fullName))])])];
      }
    }, {
      key: "cell(committees)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('ba-base-icon', {
          attrs: {
            "name": "ba-account-group",
            "height": "20px"
          }
        }), _c('div', {
          staticClass: "ml-2"
        }, [_vm._v(_vm._s(data.item.committees))])], 1)];
      }
    }, {
      key: "cell(roles)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "roles d-flex justify-content-center"
        }, _vm._l(data.item.roleIds, function (role, ix) {
          return _c('ba-badge', {
            key: ix,
            attrs: {
              "text": role
            }
          });
        }), 1)];
      }
    }, {
      key: "cell(position)",
      fn: function (data) {
        return [_c('p', {
          staticClass: "position-text mb-0"
        }, [_vm._v(_vm._s(data.item.position))])];
      }
    }, {
      key: "cell(action)",
      fn: function (data) {
        return [_c('b-dropdown', {
          attrs: {
            "menu-class": "my-0 py-0",
            "variant": "transparent",
            "no-caret": "",
            "boundary": "viewport"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, _vm._l(_vm.getActions(data.item.roleIds), function (itemOption, ix) {
          return _c('b-dropdown-item', {
            key: ix,
            on: {
              "click": function ($event) {
                return itemOption.action(data.item);
              }
            }
          }, [_c('div', {
            staticClass: "option--text py-2"
          }, [_vm._v(_vm._s(itemOption.text))])]);
        }), 1)];
      }
    }, {
      key: "cell(entry_status)",
      fn: function (data) {
        return [data.item.entry_status ? _c('b-badge', {
          staticClass: "px-3 py-2",
          attrs: {
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(data.item.entry_status) + " ")]) : _vm._e()];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }