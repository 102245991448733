var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "profile-info pt-4 px-4 mb-5"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": _vm.editProfileInfo ? 12 : 12,
      "cols": "12"
    }
  }, [_c('b-row', _vm._l(_vm.formFields, function (field) {
    return _c('b-col', {
      key: field.name,
      attrs: {
        "cols": "12"
      }
    }, [!_vm.editProfileInfo ? _c('div', {
      staticClass: "mb-4"
    }, [_c('p', {
      staticClass: "label--text mb-1"
    }, [_vm._v(_vm._s(field.label))]), _c('div', {
      staticClass: "details--text"
    }, [_vm._v(" " + _vm._s(_vm.profile[field.key]) + " ")])]) : _c('div', {
      staticClass: "mb-4"
    }, [field.type !== 'phone' ? _c('ba-text-field', {
      staticClass: "text-black-90",
      attrs: {
        "disabled": field.disabled,
        "label": field.label,
        "type": field.type,
        "classText": "white",
        "required": ""
      },
      on: {
        "blur": function ($event) {
          _vm.$v.profile[field.key].$touch();
        }
      },
      model: {
        value: _vm.profile[field.key],
        callback: function ($$v) {
          _vm.$set(_vm.profile, field.key, $$v);
        },
        expression: "profile[field.key]"
      }
    }) : _c('ba-phone-field', {
      staticClass: "text-black-90",
      attrs: {
        "label": field.label,
        "classText": "white",
        "required": ""
      },
      on: {
        "blur": function ($event) {
          _vm.$v.profile[field.key].$touch();
        }
      },
      model: {
        value: _vm.profile[field.key],
        callback: function ($$v) {
          _vm.$set(_vm.profile, field.key, $$v);
        },
        expression: "profile[field.key]"
      }
    }), _vm.$v.profile[field.key].$error ? _c('div', {
      staticClass: "error"
    }, [_vm._v(" " + _vm._s(field.label) + " is required ")]) : _vm._e()], 1)]);
  }), 1)], 1), _vm.editProfileInfo ? _c('b-col', {
    staticClass: "d-flex flex-row justify-content-between align-items-center my-4",
    attrs: {
      "lg": _vm.editProfileInfo ? 12 : 12,
      "cols": "12"
    }
  }, [_c('ba-button', {
    staticClass: "text-black bg-gray-40 border-0",
    attrs: {
      "text": "Cancel"
    },
    on: {
      "click": function ($event) {
        _vm.editProfileInfo = false;
      }
    }
  }), _c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "loading": _vm.loading,
      "text": "Save Changes",
      "variant": "warning"
    },
    on: {
      "click": _vm.saveProfile
    }
  })], 1) : _vm._e()], 1)], 1), !_vm.editProfileInfo ? _c('b-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "edit__btn"
  }, [_c('p', {
    staticClass: "edit__text"
  }, [_vm._v("Last edited " + _vm._s(_vm.formatDate(_vm.profileData.dateModified)))]), _c('ba-button', {
    staticClass: "text-white bg-gray-dark text-sm-3",
    attrs: {
      "icon": "ba-edit-new",
      "text": "Edit information"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.toggleProfileInfo.apply(null, arguments);
      }
    }
  })], 1)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }