var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "profile-image-section d-flex flex-column justify-content-center border border-gray-20 bg-gray-10 rounded-custom"
  }, [_c('h2', {
    staticClass: "header--text mb-4"
  }, [_vm._v("Company logo")]), _c('div', [_c('div', {
    staticClass: "company__logo bg-gray-40 border rounded-custom border-gray-40"
  }, [_vm.companyLogoId ? _c('img', {
    attrs: {
      "src": _vm.companyLogoUrl,
      "width": "80",
      "alt": "firm-img"
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/pngs/firm-large.png"),
      "width": "80",
      "alt": "firm-img"
    }
  })])]), _c('div', {
    staticClass: "profile-image-actions d-flex align-items-center justify-content-start mt-5 mb-3"
  }, [_c('ba-button-upload', {
    staticClass: "rounded",
    attrs: {
      "disabled": !_vm.isCompanyProfileComplete,
      "button-icon": _vm.isEditingInfo && _vm.companyLogoId ? 'ba-edit-new' : _vm.companyLogoId ? 'ba-edit-new' : 'ba-upload-icon',
      "classText": _vm.isEditingInfo && _vm.companyLogoId ? 'text-white bg-gray-dark' : _vm.companyLogoId ? 'text-white bg-gray-dark' : 'bg-yellow-70 text-gray-dark',
      "button-text": _vm.isEditingInfo && _vm.companyLogoId ? 'Update logo' : _vm.companyLogoId ? 'Update logo' : 'Upload logo'
    },
    on: {
      "completed": _vm.processFileUpload
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }