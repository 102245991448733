
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
    name: "CompanyLogos",
    computed: {
        ...mapGetters({
            companyData: "company/getProfile",
            files: "file/getFiles",
            profileData: "auth/getUserData"
        }),
        isCompanyProfileComplete(): boolean {
            return this.profileData.isCompanyProfileUpdated;
        },
        companyLogoId(): string {
            if (this.companyData && this.companyData.companyProfile) {
                const { logoImgUrl } = this.companyData.companyProfile;
                return logoImgUrl;
            }
            return "";
        },
        companyLogoUrl(): string {
            const imageObj = this.files[this.companyLogoId];
            return (imageObj && imageObj.presignedUrl) || null;
        },
    },
    data() {
        return {
            loading: false,
            isEditingInfo: false,
        };
    },
    async mounted() {
        await this.getFileUrl(this.companyLogoId);
    },
    methods: {
        toggleEditingInfo(val: boolean) {
            this.isEditingInfo = val;
        },
        ...mapActions({
            updateCompanyProfile: "company/updateProfile",
            fetchCompanyProfile: "company/fetchProfile",
            uploadFile: "file/uploadFile",
            fetchFile: "file/fetchFile",
            removeFile: "file/removeFile",
        }),
        ...mapActions("company", ["setCompanyState"]),
        async getFileUrl(filename: string): Promise<void> {
            try {
                await this.fetchFile(filename);
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to fetch file!",
                    variant: "danger",
                    solid: true,
                });
            }
        },
        async processFileUpload(file: any): Promise<void> {
            const { fileName } = file;
            await this.saveProfile({
                ...this.companyData.companyProfile,
                logoImgUrl: fileName,
            }).then(async () => {
                try {
                    await this.fetchCompanyProfile();
                    await this.getFileUrl(fileName);
                    const currentStep = Number(
                    this.$route.query["onboarding-step"],
                );
                if (currentStep && this.companyLogoId) {
                    this.setCompanyState(true)
                }
                } catch (e) {
                    this.$bvToast.toast("", {
                        title: "Unable to fetch profile!",
                        variant: "danger",
                        solid: true,
                    });
                }
            });
        },
        async saveProfile(payload: any): Promise<void> {
            this.loading = true;
            try {
                await this.updateCompanyProfile(payload);
                this.$bvToast.toast("", {
                    title: "Profile Update Successful!",
                    variant: "success",
                    solid: true,
                });
            } catch (e) {
                this.$bvToast.toast("", {
                    title: "Unable to update profile!",
                    variant: "danger",
                    solid: true,
                });
            }
            this.loading = false;
        },
    },
});
