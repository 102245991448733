
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { formatDate } from "@/utils/helpers";

export default Vue.extend({
    name: "credentialsModal",
    props: {
        user: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data(): any {
        return {
            isLoading: false,
            loading: false,
        };
    },
    computed: {
        ...mapState("users", ["userCredentials"]),
        ...mapGetters({
            companyData: "company/getProfile",
        }),
        userCredentialsInfo(): string {
            return `User Id: ${this.userCredentials.username} Password: ${this.userCredentials.password}`;
        },
    },
    watch: {
        async user(val) {
            console.log(val);
            try {
                this.isLoading = true;
                await this.sendCredentialsToUser({ id: val.id, body: { email: val.email, companyRCNumber: this.companyData.companyProfile.rcNumber } });
                this.isLoading = false;
            } catch (error: unknown) {
                this.isLoading = false;
            }
        },
    },
    methods: {
        formatDate,
        ...mapActions("users", ["sendCredentialsToUser"]),
        onCopyCode() {
            this.$bvToast.toast("User credentials copied successfully", { title: `Success`, variant: "success", solid: true });
        },
    },
});
