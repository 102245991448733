
import Vue from "vue";

export default Vue.extend({
    name: "MoreInfo",
    props: {
    },
    data(): any {
        return {
            roles: [
                { title: 'Admin', description: 'A user that has administrative rights to the system. They can edit a company’s information, and also add/remove/edit users on the system.'},
                { title: 'Creator', description: 'A user that starts the reporting process by creating a report. After creation, the report is sent to a reviewer for approval.'},
                { title: 'Reviewer', description: 'A user that reviews and approve reports before it goes to the signatories’ desk.'},
                { title: 'Signatory', description: 'A user that signs a report before it is sent to the regulator. According to the FRC, only the chairman of the board and the company secretary are permitted to sign reports.'},
                { title: 'Profile-Only', description: 'A profile that is not a user account, hence cannot log into the system. You can convert a profile to a user account by assigning a role to it.'}
            ]
        };
    },
    computed: {
    },
    methods: {
    },
});
