var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-page-banner', {
    staticClass: "corporate-universe__banner px-5 py-4 mb-5",
    attrs: {
      "dark": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "text-white h3 font-weight-bold pt-2"
        }, [_vm._v("Settings")])];
      },
      proxy: true
    }, {
      key: "description",
      fn: function () {
        return [_c('div', {
          staticClass: "text-gray-300 pb-2"
        }, [_vm._v(" Edit personal, company and user settings ")])];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }