var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "profile-image-section img-section border border-gray-20 bg-gray-10 rounded-custom mb-4"
  }, [_c('h2', {
    staticClass: "header--text mb-4"
  }, [_vm._v("Profile photo")]), _c('div', {
    staticClass: "d-flex flex-column justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm.profileImageId ? _c('b-img-lazy', {
    staticClass: "profile-image",
    attrs: {
      "alt": _vm.getInitials,
      "src": _vm.profileImageUrl,
      "blank-color": "#5c4a1f",
      "height": "200px",
      "rounded": "circle",
      "width": "200px"
    }
  }) : _c('div', {
    staticClass: "default-profile-image"
  }, [_c('p', {
    staticClass: "mb-0 text-white"
  }, [_vm._v(_vm._s(_vm.getInitials))])])], 1), _c('div', {
    staticClass: "profile-image-actions mt-5"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-column justify-content-center"
  }, [_c('ba-button-upload', {
    attrs: {
      "button-icon": "ba-edit-dark",
      "button-text": "Update",
      "classText": "bg-white border border-gray-40 text-gray-dark"
    },
    on: {
      "completed": _vm.processFileUpload
    }
  }), _c('ba-button', {
    staticClass: "mt-3 font-weight-bold bg-black-10 text-black-40 border-0",
    attrs: {
      "loading": _vm.isDeleteLoading,
      "classText": "text-black-40",
      "icon": "trash-icon",
      "text": "Remove"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.removeProfileImage.apply(null, arguments);
      }
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }