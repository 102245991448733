<template>
  <div class="container-fluid py-6 mt-body bg-gray-10 min-vh-100">
    <div v-if="isProfileCompleted" class="checklist">
            <ba-base-icon name="ba-cancel-modal" @click.native="closeModal" class="checklist__cancel-icon"></ba-base-icon>    
            <div>
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="checklist__title">CHECKLIST</h1>
                    <div class="checklist-counter">
                        <p class="checklist-counter__count">{{progress}}/3</p>
                    </div>
                </div>
                <b-form-checkbox
                    id="checkbox-onboarding-2"
                    disabled
                    :checked="isCompanyProfileComplete"
                    name="checkbox-onboarding-2"
                    class="d-flex align-items-center"
                >
                    <div class="checklist-checked__text">Complete Company Profile</div>
                </b-form-checkbox>
                <b-form-checkbox
                    id="checkbox-onboarding-2"
                    disabled
                    :checked="isPersonalProfileComplete"
                    name="checkbox-onboarding-2"
                    class="d-flex mt-3 align-items-center"
                >
                    <div class="checklist-checked__text">Complete personal setup</div>
                </b-form-checkbox>
                <h1 class="checklist__title pt-4">NEXT</h1>
                <b-form-checkbox
                    id="checkbox-onboarding-2"
                    disabled
                    :checked="false"
                    name="checkbox-onboarding-2"
                    class="d-flex align-items-center"
                >
                    <div class="checklist-checked__text">Add 2 signatories</div>
                </b-form-checkbox>
                <ba-button
                    class="text-gray-dark border-0 bg-yellow-70 mt-4"
                    text="Continue"
                    @click="goToNextStep"
                />
            </div>
        </div>
    <pageHeader title="My Profile" description="Edit your personal information here." />
    <b-row>
      <b-col cols="12">
        <ba-loader-wrapper :isLoading="isLoading">
          <b-row>
            <b-col cols="12" lg="7">
              <section class="profile-info-section pl-5 lg-pr-0 pr-5 pt-lg-0 pt-0 lg-pt-4">
                <edit-profile/>
                <!-- <change-password/> -->
              </section>
            </b-col>
            <b-col cols="12" lg="5" class="pr-5 pl-lg-0 pl-5 pb-lg-0 pb-5">
              <profile-image/>
            </b-col>
          </b-row>
        </ba-loader-wrapper>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import EditProfile from "@/app/components/sections/profile/EditProfile.vue";
// import ChangePassword from "@/app/components/sections/profile/ChangePassword.vue";
import ProfileImage from "@/app/components/sections/profile/ProfileImage.vue";
import pageHeader from "@/shared-components/BaPageHeader.vue"
import { SETTINGS } from "@/app/routes/endpoints";
import { mapActions, mapGetters, mapState } from "vuex";

export default Vue.extend({
  components: {
    EditProfile,
    // ChangePassword,
    ProfileImage,
    pageHeader
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
        ...mapState("users", ["isProfileCompleted"]),
        isCompanyProfileComplete() {
          return this.profileData.isCompanyProfileUpdated;
        },
        isPersonalProfileComplete() {
          return this.profileData.isProfileUpdated;
        },
        progress() {
            let count = 0;

            if (this.isCompanyProfileComplete) {
                count += 1;
            }
            if (this.isPersonalProfileComplete) {
                count += 1;
            }

            return count;
        },
    },
  methods: {
    ...mapActions({
      fetchProfile: "auth/fetchProfile",
    }),
    goToNextStep () {
            const currentStep = Number(
                this.$route.query["onboarding-step"],
            );
            if (currentStep) {
                this.$router.push({
                  name: SETTINGS.USER_ACCESS.NAME,
                    query: {
                        "onboarding-step": currentStep + 1,
                    },
                });
                this.closeModal()
            }
        },
        closeModal () {
          this.$store.commit("users/storeProfileState", false)
        }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.fetchProfile()
    } catch (e) {
      this.$bvToast.toast('', {
        title: "Unable to fetch profile!",
        variant: "danger",
        solid: true,
      });
    }
    this.isLoading = false
  }
});
</script>

<style lang="scss" scoped>
.container-fluid {
  width: 100%;
  padding-right: 80px;
  padding-left: 80px;
  margin-right: auto;
  margin-left: auto;
}
.company-wrapper {
    padding: 0px 50px;
}
.checklist {
    position: absolute;
    right: 20px;
    width: 384px;
    background: linear-gradient(180deg, #FFDA4D 0%, #FFEFB3 100%);
    border: 1px solid #FFEA99;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    z-index: 1;
    padding: 24px 32px;
    &__cancel-icon {
        position: absolute;
        top: -10px;
        left: -8px;
    }
    &__title {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #383637;
    }
    &-counter {
        border: 2px solid #997900;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        &__count {
            display: flex;
            justify-content: center;
            align-items: center; 
            margin-bottom: 0;
            padding: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #383637;
        }
    }
    &-checked {
        &__text {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #383637;
            padding-top: 2px;
        }
    }
}
</style>
