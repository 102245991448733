var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "faq__text--header"
  }, [_vm._v("How Tos")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "form-control input__field mt-3 mb-2",
    attrs: {
      "type": "text",
      "placeholder": "Search..."
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.search = $event.target.value;
      }
    }
  }), _vm._l(_vm.filteredQAs, function (data, index) {
    return _c('section', {
      key: index
    }, [data.type == 'headTitle' ? _c('h4', {
      staticClass: "pt-3 setup-title"
    }, [_vm._v(_vm._s(data.headTitle))]) : _vm._e(), data.type === 'QA' ? _c('div', {
      staticClass: "question-section bg-gray-dark px-3 py-3 mb-3"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center mb-0 pointer",
      on: {
        "click": function ($event) {
          return _vm.collapsedPart(index);
        }
      }
    }, [_c('p', {
      staticClass: "text-black-10 mb-0"
    }, [_vm._v(_vm._s(data.question))]), _c('div', {
      staticClass: "pointer mt-2"
    }, [_c('ba-base-icon', {
      attrs: {
        "name": "ba-arrow-down"
      }
    })], 1)]), _c('div', {
      staticClass: "answer-section text-white",
      class: _vm.collapsedPartIds.find(function (i) {
        return i === index;
      }) !== undefined ? 'p-3 mt-3' : 'hide_form p-0'
    }, _vm._l(data.answer, function (answer, ix) {
      return _c('p', {
        key: ix
      }, [_vm._v(_vm._s(answer))]);
    }), 0)]) : _vm._e()]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }