var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "bg-black-100 container-fluid"
  }, [_c('header', {
    staticClass: "pt-2 d-flex justify-content-between align-items-center"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-nccg-logo"
    }
  }), _c('ba-button', {
    staticClass: "text-white border-0 bg-gray-dark",
    attrs: {
      "text": _vm.buttonText,
      "type": "button"
    },
    on: {
      "click": _vm.takeAction
    }
  })], 1), _c('div', {
    staticClass: "faq-header mt-5 d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h2', {
    staticClass: "faq__text"
  }, [_vm._v("Help Centre")]), _c('p', {
    staticClass: "faq__subtext"
  }, [_vm._v("For more general information about the Reporting Portal, visit the "), _c('span', [_c('a', {
    staticClass: "product-guide-btn",
    attrs: {
      "href": "https://www.bodadmin.com/learn-more/reporting-portal",
      "target": "_blank"
    }
  }, [_vm._v("Product guide "), _c('ba-base-icon', {
    attrs: {
      "name": "ba-open-icon"
    }
  })], 1)])])]), _c('ba-base-icon', {
    attrs: {
      "name": "ba-faq-icon"
    }
  })], 1), _c('b-row', {
    staticClass: "pb-6 pt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "faq__wrapper"
  }, [_c('cu-how-to')], 1)]), _c('b-col', {
    staticClass: "mt-lg-0 mt-5",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "faq__wrapper"
  }, [_c('cu-faq')], 1)])], 1), _c('div', {
    staticClass: "report__wrapper--footer"
  }, [_c('p', [_vm._v("Developed for Financial Reporting Council of Nigeria")]), _c('p', [_vm._v("By Bodadmin Limited")]), _c('p', [_vm._v("©" + _vm._s(new Date().getFullYear()))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }