
import Vue from "vue";

export default Vue.extend({
    props: {
        title: {
            type: String,
            required: true,
        },
        logo: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        route: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isHovered: false,
        };
    },
    methods: {
        goToPage() {
            this.$router.push({ name: this.route });
        },
    },
});
