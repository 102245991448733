
import Vue from "vue";
import isUserLoggedIn from "@/utils/auth";
import CuFaq from "../../components/home/CuFaq.vue";
import CuHowTo from "../../components/home/CuHowTo.vue";
import { HOME, AUTH } from "../../routes/endpoints";

export default Vue.extend({
    components: { CuFaq, CuHowTo },
    data() {
        return {};
    },
    computed: {
        buttonText () {
            let text = 'Login'
            if (isUserLoggedIn()) {
                text = 'Go Home'
            } else {
                text = 'Login'
            }
            return text
        }
    },
    methods: {
        takeAction () {
            if (isUserLoggedIn()) {
                this.$router.push({
                    name: HOME.INDEX.NAME,
                });
            } else {
                this.$router.push({
                    name: AUTH.LOGIN.NAME,
                });
            }
        }
    }
});
