var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": "view-user",
      "title": "User Details",
      "description": "Learn more about this individual. You can edit",
      "custom-content-class": "overflow-scroll selectable-modal__content",
      "apply-body-classes": false,
      "has-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap align-items-center"
        }, [_c('ba-button', {
          staticClass: "text-white bg-gray-dark",
          attrs: {
            "text": "Edit",
            "icon": "ba-edit-new"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('openEditModal', _vm.user);
            }
          }
        }), _c('ba-button', {
          staticClass: "text-black",
          attrs: {
            "text": "Close",
            "variant": "light"
          },
          on: {
            "click": function ($event) {
              return _vm.$bvModal.hide('modal-view-user');
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm.user ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-2"
  }, [_vm._v("User ID")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.user.frcId || "Nil"))])]), _c('div', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-2"
  }, [_vm._v("Full Name")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.user.fullName))])]), _c('div', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-2"
  }, [_vm._v("Email Address")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.user.email))])]), _c('div', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-2"
  }, [_vm._v("Phone Number")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.user.phoneNumber))])]), _c('div', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-2"
  }, [_vm._v("Gender")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.user.gender === 1 ? "Male" : "Female"))])]), _c('div', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-2"
  }, [_vm._v("Date of Appointment")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.user.dateOfAppointment)))])]), _c('div', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-2"
  }, [_vm._v("Position")]), _c('p', {
    staticClass: "committee__description"
  }, [_vm._v(_vm._s(_vm.user.position))])]), _c('div', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "committee__name mb-2"
  }, [_vm._v("Roles")]), _vm.user.roleIds.length === 0 ? _c('b-badge', {
    staticClass: "px-3 py-2 rounded profile-badge",
    attrs: {
      "pill": ""
    }
  }, [_vm._v("Profile-Only")]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap",
    staticStyle: {
      "gap": "8px"
    }
  }, _vm._l(_vm.user.roleIds, function (role, ix) {
    return _c('ba-badge', {
      key: ix,
      attrs: {
        "text": role
      }
    });
  }), 1)], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }