var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid py-6 mt-body bg-gray-10 min-vh-100"
  }, [_vm.showModal ? _c('div', {
    staticClass: "checklist"
  }, [_c('ba-base-icon', {
    staticClass: "checklist__cancel-icon",
    attrs: {
      "name": "ba-cancel-modal"
    },
    nativeOn: {
      "click": function ($event) {
        _vm.showModal = false;
      }
    }
  }), _c('div', [_vm._m(0), _c('b-form-checkbox', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "id": "checkbox-onboarding-2",
      "disabled": "",
      "checked": _vm.isCompanyProfileComplete,
      "name": "checkbox-onboarding-2"
    }
  }, [_c('div', {
    staticClass: "checklist-checked__text"
  }, [_vm._v("Complete Company Profile")])]), _c('b-form-checkbox', {
    staticClass: "d-flex mt-3 align-items-center",
    attrs: {
      "id": "checkbox-onboarding-2",
      "disabled": "",
      "checked": _vm.hasAReviewer,
      "name": "checkbox-onboarding-2"
    }
  }, [_c('div', {
    staticClass: "checklist-checked__text"
  }, [_vm._v("Add a Reviewer")])]), _c('b-form-checkbox', {
    staticClass: "d-flex mt-3 align-items-center",
    attrs: {
      "id": "checkbox-onboarding-2",
      "disabled": "",
      "checked": _vm.hasASignatory,
      "name": "checkbox-onboarding-2"
    }
  }, [_c('div', {
    staticClass: "checklist-checked__text"
  }, [_vm._v("Add 2 Signatories (Board Chairman & Company Secretary)")])]), _c('ba-button', {
    staticClass: "bg-gray-dark border-0 text-white mt-4",
    attrs: {
      "text": "Start reporting"
    },
    on: {
      "click": _vm.goToNextStep
    }
  })], 1)], 1) : _vm._e(), _c('pageHeader', {
    attrs: {
      "title": "Users, Roles and Profiles",
      "description": "Add/Remove admins, creators, reviewers and signatories"
    }
  }), _c('b-row', {
    staticClass: "bg-white mx-0 mt-5 border border-gray-light rounded-custom",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-nav', {
    staticClass: "ba-nav flex-nowrap"
  }, [_vm._l(_vm.tabs, function (tab, ix) {
    return _c('b-nav-item', {
      key: ix,
      staticClass: "nav-item",
      attrs: {
        "to": {
          query: {
            tab: tab.value
          }
        },
        "active": _vm.isActiveTab(tab.value),
        "replace": "",
        "active-class": "text-yellow-150"
      }
    }, [_c('div', {
      staticClass: "mt-2"
    }, [_c('div', {
      staticClass: "navmenu--text",
      class: {
        'text-black-40': !_vm.isActiveTab(tab.value),
        'text-yellow-150': _vm.isActiveTab(tab.value)
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]), _vm.isActiveTab(tab.value) ? _c('div', {
      staticClass: "nav-item__border"
    }) : _vm._e()])]);
  }), _c('div', {
    staticClass: "w-100 nav-item__divider"
  })], 2)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c(_vm.currentTab.component, _vm._b({
    tag: "component",
    on: {
      "toggleUpdate": _vm.toggleUpdate,
      "createUser": _vm.toggleCreate,
      "toggleUserView": _vm.toggleView,
      "toggleCredentials": _vm.toggleCredentials
    }
  }, 'component', {
    isLoadingOutside: _vm.isLoading
  }, false)), _c('create-user-modal', {
    attrs: {
      "edit": _vm.isEdit,
      "user": _vm.user,
      "modal-id": "create-user"
    },
    on: {
      "completed": _vm.loadAfterCompletion,
      "resetEdit": function ($event) {
        _vm.isEdit = false;
      }
    }
  }), _c('preview-user-modal', {
    attrs: {
      "user": _vm.user,
      "modal-id": "view-user"
    },
    on: {
      "openEditModal": _vm.toggleUpdate
    }
  }), _c('credentials-modal', {
    attrs: {
      "user": _vm.credUser,
      "modal-id": "generate-credentials"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h1', {
    staticClass: "checklist__title"
  }, [_vm._v("CHECKLIST – "), _c('span', {
    staticStyle: {
      "color": "#1a936b"
    }
  }, [_vm._v("COMPLETED")])]), _c('div', {
    staticClass: "checklist-counter"
  }, [_c('p', {
    staticClass: "checklist-counter__count"
  }, [_vm._v("3/3")])])]);

}]

export { render, staticRenderFns }