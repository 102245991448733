var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'generate-credentials',
      "title": "Generate Credentials",
      "description": `Generate new access credentials for this user.`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "text-center"
  }, [_c('ba-loader')], 1) : _vm._e(), _vm.user && _vm.userCredentials && !_vm.isLoading ? _c('section', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-start rounded-4 bg-gray-10 border border-gray-light p-4"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "default-profile-image mr-2"
  }, [_c('p', {
    staticClass: "mb-0 text-white text-uppercase"
  }, [_vm._v(_vm._s(_vm.user.fullName[0].toUpperCase()) + _vm._s(_vm.user.fullName[1].toUpperCase()))])]), _c('div', {
    staticClass: "user"
  }, [_c('p', {
    staticClass: "user__name mb-1"
  }, [_vm._v(_vm._s(_vm.user.fullName))]), _c('p', {
    staticClass: "user__email mb-1"
  }, [_vm._v(_vm._s(_vm.user.email))]), _c('p', {
    staticClass: "user__position mb-0"
  }, [_vm._v(_vm._s(_vm.user.position))])])]), _c('div', {
    staticClass: "user__roles"
  }, [_vm.user.roleIds.length === 0 ? _c('b-badge', {
    staticClass: "px-3 py-2 rounded profile-badge",
    attrs: {
      "pill": ""
    }
  }, [_vm._v("Profile-Only")]) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap",
    staticStyle: {
      "gap": "8px"
    }
  }, _vm._l(_vm.user.roleIds, function (role, ix) {
    return _c('b-badge', {
      key: ix,
      staticClass: "px-3 py-2 rounded",
      class: [role === 'Admin' ? 'admin-badge' : role === 'ReportSignatory' ? 'bg-green-10 text-green-70' : role === 'ReportCreator' ? 'bg-gray-20 text-black-70' : role === 'ReportReviewer' ? 'bg-yellow-10 text-yellow-130' : 'bg-red-10 text-red-70'],
      attrs: {
        "pill": ""
      }
    }, [_vm._v(_vm._s(role) + " ")]);
  }), 1)], 1)]), _c('div', {
    staticClass: "mt-4 d-flex flex-column align-items-center justify-content-center"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-cred-check"
    }
  }), _c('p', {
    staticClass: "user__cred-title mb-0 py-3"
  }, [_vm._v("New access credentials have been generated for this user.")]), _c('div', {
    staticClass: "bg-blue-10 rounded-8 border border-blue-20 d-flex justify-content-between align-items-start p-3",
    staticStyle: {
      "width": "400px"
    }
  }, [_c('div', [_c('p', {
    staticClass: "user__user-creds mb-3"
  }, [_vm._v("User Id: " + _vm._s(_vm.userCredentials.username))]), _c('p', {
    staticClass: "user__user-creds mb-0"
  }, [_vm._v("Password: " + _vm._s(_vm.userCredentials.password))])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:copy",
      value: _vm.userCredentialsInfo,
      expression: "userCredentialsInfo",
      arg: "copy"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyCode,
      expression: "onCopyCode",
      arg: "success"
    }],
    staticClass: "d-flex align-items-center pointer"
  }, [_c('ba-base-icon', {
    attrs: {
      "name": "ba-cred-copy"
    }
  }), _c('p', {
    staticClass: "mb-1 user__copy-text"
  }, [_vm._v("Copy")])], 1)]), _c('p', {
    staticClass: "user__copy-subtext my-3"
  }, [_vm._v(" The newly generated credentials have been automatically sent to this user’s registered email. However, you can also copy and share if for any reason the user does not receive the email. ")])], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }