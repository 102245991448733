var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'more-info',
      "title": 'Learn more',
      "description": "",
      "custom-content-class": "overflow-scroll selectable-modal__content",
      "icon": "ba-info-icon",
      "isIconRequired": true
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "mr-3 mb-4 text-blue-90"
  }, [_vm._v("You can assign multiple roles to a user. You don't need to duplicate a user to have them perform multiple roles.")]), _c('h3', {
    staticClass: "badge__header"
  }, [_vm._v("Understanding roles")]), _vm._l(_vm.roles, function (role, index) {
    return _c('div', {
      key: index,
      staticClass: "mt-3"
    }, [_c('b-badge', {
      staticClass: "px-3 rounded py-2 mb-2",
      class: role.title === 'Admin' ? 'bg-blue-10 text-blue-70' : role.title === 'Signatory' ? 'bg-green-10 text-green-70' : role.title === 'Creator' ? 'bg-gray-20 text-black-70' : role.title === 'Reviewer' ? 'bg-yellow-10 text-yellow-130' : role.title === 'Profile-Only' ? 'bg-red-10 text-red-70' : '',
      attrs: {
        "pill": ""
      }
    }, [_vm._v(_vm._s(role.title))]), _c('p', {
      staticClass: "badge__description"
    }, [_vm._v(_vm._s(role.description))])], 1);
  })], 2)], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-between align-items-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Close",
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('modal-more-info');
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }